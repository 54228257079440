import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';

// Initialize the "no data" module
noDataToDisplay(Highcharts);

function Linecharts({ value,id }) {
  useEffect(() => {
    const chart = Highcharts.chart(id, {
      title: {
        text: '', // Keep empty if no title is required
        align: 'left',
        margin: 10
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: value?.y_title || 'Y-Axis' // Use the passed y_title or default
        }
      },
      xAxis: {
        type: 'datetime',
        categories: value?.x_axis?.length
          ? value?.x_axis.map(timestamp => new Date(timestamp).toLocaleDateString()) 
          : [], // Use readable dates or empty array if no data
        title: {
          text: value?.x_title || 'X-Axis' // Use the passed x_title or default
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          marker: {
            enabled: true,
            symbol: 'circle'
          },
          dataLabels: {
            enabled: false
          }
        }
      },
      series: value?.y_axis?.length 
        ? value?.y_axis.map(series => ({
          name: series.name,
          data: series.data.map(point => ({
            y: point,
            marker: {
              enabled: point !== 0 // Disable marker if the value is 0
            }
          }))
        }))
        : [], // If no series, pass an empty array
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    });

    // Show "No data" message if no data is provided
    if (!value?.x_axis?.length || !value?.y_axis?.length) {
      chart.showNoData('No data available'); // Display the message
    }

    return () => {
      chart.destroy();
    };
  }, [value]);

  return <div id={id} style={{ height: "300px" }} />;
}

export default Linecharts;
