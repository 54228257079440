import axios from "../../../utils/axios";

export const GetTrendChart = async (start_date,end_date) => {
  let response = await axios(
    process.env.REACT_APP_WEBBOT +
      `leaderboard/${localStorage.getItem("ci")}/trend_chart`,
    {
      method: "POST",
      data:{start_date,end_date}
    }
  );

  return { data: response.data, status: response.status };
};

export const ExportTrendChart = async (
   start_date,end_date
   ) => {
     try {
       const response = await axios(
         process.env.REACT_APP_WEBBOT +
           `leaderboard/${localStorage.getItem("ci")}/history/export`,
         {
           method: "POST",
           headers: {
             "Access-Control-Allow-Origin": "*",
           },
           responseType: "blob",
           data: {
            start_date,end_date
           },
         }
       );
   
       if (response.headers["content-disposition"]) {
         const contentDisposition = response.headers["content-disposition"];
         const filenameMatch = contentDisposition.match(
           /filename\*=utf-8''(.+?)(;|$)/
         );
         if (filenameMatch) {
           const filename = decodeURIComponent(filenameMatch[1]);
           return { response, filename };
         }
       }
   
       // If content-disposition header or filename not found, use a default filename
       return { response, filename: "default_filename.xlsx" };
     } catch (error) {
       console.error("Error in DownloadTopIssues:", error);
       throw error; // Rethrow the error for higher-level error handling
     }
   };